<template>
  <div class="DemandSort">
    <ds-header title="需求分类管理"></ds-header>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addDemandCategory"> <a-icon type="plus" /> 新增一级分类 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :columns="table.columns"
        :data-source="table.dataInfo"
        :rowKey="(record, index) => record.classifyId"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
        :childrenColumnName="table.childrenColumnName"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="editDemandCategory(record)"> 编辑 </a-button>
          <a-button
            class="btnClass"
            type="link"
            @click="addSceondLevelDemandCategory(record,'二')"
            v-if="!record.parentClassifyId"
          >
            添加二级分类
          </a-button>
          <a-button
          v-if="record.rank==2"
            class="btnClass"
            type="link"
            @click="addSceondLevelDemandCategory(record,'三')"
          >
            添加三级分类
          </a-button>
          <a-button class="btnClass" type="link" style="color: #f5222d" @click="deleteDemandCategory(record)">
            删除
          </a-button>
        </span>
      </a-table>
    </div>
    <AddDemandCategory
      v-if="showAddDemandCategory"
      :show-add-demand-category.sync="showAddDemandCategory"
      :isAddFirstLevelCategory="isAddFirstLevelCategory"
      :parentCategoryName="parentCategoryName"
      :parentCategoryId="parentCategoryId"
      @refreshDemandCategoryList="refreshDemandCategoryList"
    />
    <EditDemandCategory
      v-if="showEditDemandCategory"
      :show-edit-demand-category.sync="showEditDemandCategory"
      :isEditFirstLevelCategory="isEditFirstLevelCategory"
      :classifyName="classifyName"
      :classifyId="classifyId"
      @refreshDemandCategoryList="refreshDemandCategoryList"
    />
  </div>
</template>

<script>
import * as api from '@/api/demandPool';
import { SUCCESS_CODE } from '@/config';
import { demandPoolCategoryTableConfig } from '../help';
import AddDemandCategory from './components/addDemandCategory';
import EditDemandCategory from './components/editDemandCategory';
export default {
  name: 'DemandSort',
  components: {
    AddDemandCategory,
    EditDemandCategory,
  },
  data() {
    return {
      showAddDemandCategory: false, // 显示添加需求分类
      isAddFirstLevelCategory: true, // 是否是添加一级分类 true: 一级分类、 false: 二级分类
      parentCategoryName: '', // 上级分类名称
      parentCategoryId: null, // 上级分类id
      showEditDemandCategory: false, // 显示编辑需求分类
      isEditFirstLevelCategory: true, // 是否是编辑一级分类 true: 一级分类、 false: 二级分类
      classifyName: '', // 编辑分类名称
      classifyId: '', // 编辑分类id
      table: demandPoolCategoryTableConfig,
    };
  },
  methods: {
    // 添加需求池分类
    addDemandCategory() {
      this.parentCategoryName = '';
      this.parentCategoryId = null;
      this.showAddDemandCategory = true;
      this.isAddFirstLevelCategory = '一';
    },
    // 添加二级分类
    addSceondLevelDemandCategory(item,num) {
      this.parentCategoryName = item.classifyName;
      this.parentCategoryId = item.classifyId;
      this.showAddDemandCategory = true;
      this.isAddFirstLevelCategory = num;
    },
    // 编辑需求分类
    editDemandCategory(item) {
      this.classifyName = item.classifyName;
      this.classifyId = item.classifyId;
      this.showEditDemandCategory = true;
      this.isEditFirstLevelCategory = !item.parentClassifyId ? true : false;
    },
    // 删除需求分类
    deleteDemandCategory(item) {
      const self = this;
      this.$confirm({
        title: '确认删除需求分类',
        content: '删除后此数据将无法恢复, 确定要删除吗',
        onOk() {
          self.deleteDemandCategoryRequest(item.classifyId);
        },
        onCancel() {},
      });
    },
    // 刷新需求分类列表
    refreshDemandCategoryList() {
      this.getDemandCategoryList();
    },
    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getDemandCategoryList();
    },
    /**网络请求 */
    // 获取需求池分类列表
    async getDemandCategoryList() {
      try {
        const result = await api.getDemandCategoryListFoDemandPool({
          pageNum: this.table.pagination.current,
          pageSize: this.table.pagination.pageSize,
        });
        if (result.code === SUCCESS_CODE) {
          this.demandPoolListDataDispose(result.data.list);
          this.table.dataInfo = result.data.list;
          this.table.pagination.total = result.data.total;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 删除需求分类
    async deleteDemandCategoryRequest(demandClassifyId) {
      try {
        const result = await api.deleteDemandCategoryForDemandPool(demandClassifyId);
        if (result.code === SUCCESS_CODE) {
          this.$message.success(result.msg);
          this.getDemandCategoryList();
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {}
    },
    /**数据处理 */
    // 需求池分类列表数据处理
    demandPoolListDataDispose(list) {
      list.forEach((item) => {
        if (item.childList.length) {
          this.demandPoolListDataDispose(item.childList);
        } else {
          item.childList = null;
        }
      });
    },
  },
  created() {
    this.getDemandCategoryList();
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.footerButton {
  margin-top: 10px;
  margin-right: 10px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.field {
  font-size: 12px;
  color: #333;
  display: inline-block;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80px;
}
.inputClass {
  width: 200px;
}
.inputColor {
  color: #bdbdbd !important;
}
</style>
